import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { isBlank } from '../../utils/string'
import css from './ModalNuevaNotaProfesor.module.css'

const ModalNuevaNotaProfesor = ({ open, alumno='', onSubmit, onClose }) => {
  const [nota, setNota] = useState('')

  useEffect(()=> {
    if (open) setNota('')
  }, [open])

  const isSubmitDisabled = isBlank(nota)

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm'>
      <DialogTitle>Nueva nota de {alumno}</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Añade un comentario o detalle a la ficha del alumno.
        </DialogContentText>
        <TextField
          className={css.input}
          label='Nota para su ficha'
          multiline={true}
          value={nota}
          onChange={e => setNota(e.target.value)}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          disabled={isSubmitDisabled}
          color='primary'
          onClick={()=> onSubmit({ nota })}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalNuevaNotaProfesor