import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import LeadHeader from "../../../components/LeadHeader/LeadHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import useLead from "../../../hooks/useLead"
import DatosContacto from "./components/DatosContacto/DatosContacto"
import DatosEstudios from "./components/DatosEstudios/DatosEstudios"
import DatosMarketing from "./components/DatosMarketing/DatosMarketing"
import css from './Ficha.module.css'

const Ficha = ()=> {

  const navigate = useNavigate()

  const { isLoading, lead } = useLead()

  useEffect(()=> {
    if (!lead) navigate('/leads')
  }, [lead, navigate])


  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <LeadHeader label='Ficha' />
        <div className={css.content}>
          <div className={css.accordion}>
            <DatosContacto lead={lead} />
          </div>
          <div className={css.accordion}>
            <DatosEstudios lead={lead} />
          </div>
          <div className={css.accordion}>
            <DatosMarketing lead={lead} />
          </div>
        </div>
      </div>
    </PageLoading>
  )
}

export default Ficha