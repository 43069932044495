import { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { isBlank } from '../../utils/string'
import css from './ModalValoracionPrueba.module.css'

const ModalValoracionPrueba = ({ open, lead='', valoracion, onSubmit, onClose }) => {

  const [nota, setNota] = useState('')

  useEffect(()=> {
    if (open) setNota(valoracion)
  }, [open, valoracion])

  const isSubmitDisabled = isBlank(nota)

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Valoración de la prueba de {lead}</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Indica el nivel del alumno en la prueba realizada. Esta valoración se utilizará como referencia en la 
          elaboración del presupuesto del alumno
        </DialogContentText>
        <TextField
          className={css.input}
          label='Valoración de la prueba'
          multiline={true}
          value={nota}
          onChange={e => setNota(e.target.value)}
          variant='standard'
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          disabled={isSubmitDisabled}
          color='primary'
          onClick={()=> onSubmit({ nota })}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalValoracionPrueba