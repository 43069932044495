import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import TextLabel from '../../../../../components/form/TextLabel/TextLabel'
import css from './DatosTutores.module.css'

const DatosTutores = ({ 
  tutor1,
  tutor2,
 })=> {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Tutores</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={css.details}>
        {!tutor1 && !tutor2 && (
          <Typography className={css.empty} variant='body2' component='p'>
            No hay información de tutores para este alumno
          </Typography>
        )}
        {tutor1 && (
          <div className={css.column}>
            <TextLabel
              className={css.field}
              name='relacion'
              label='Relación con el alumno'
              value={tutor1.rol}
            />
            <TextLabel
              className={css.field}
              name='nombre'
              label='Nombre'
              value={tutor1.nombre}
            />
            <TextLabel
              className={css.field}
              name='email'
              label='E-mail'
              value={tutor1.email}
            />
            <TextLabel
              className={css.field}
              name='telefono'
              label='Teléfono'
              value={tutor1.telefono}
            />
            <TextLabel
              className={css.field}
              name='dni'
              label='DNI'
              value={tutor1.dni}
            />
          </div>
        )}
        {tutor2 && (
          <div className={css.column}>
            <TextLabel
              className={css.field}
              name='relacion'
              label='Relación con el alumno'
              value={tutor2.rol}
            />
            <TextLabel
              className={css.field}
              name='nombre'
              label='Nombre'
              value={tutor2.nombre}
            />
            <TextLabel
              className={css.field}
              name='email'
              label='E-mail'
              value={tutor2.email}
            />
            <TextLabel
              className={css.field}
              name='telefono'
              label='Teléfono'
              value={tutor2.telefono}
            />
            <TextLabel
              className={css.field}
              name='dni'
              label='DNI'
              value={tutor2.dni}
            />
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosTutores