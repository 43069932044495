import { useEffect, useState } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { isBlank } from '../../utils/string'
import css from './ModalEvaluacionActitudAlumno.module.css'

const ACTITUDES = [
	'MUY_NEGATIVA',
	'NEGATIVA',
	'NEUTRAL',
	'POSITIVA',
  'MUY_POSITIVA',
]

const Face = ({ type, isSelected, onClick })=> (
  <div 
    className={css.face}
    data-selected={isSelected}
    data-type={type}
    onClick={onClick}
  >
    <i className='material-icons'>
      {type === 'MUY_POSITIVA' && 'sentiment_very_satisfied'}
      {type === 'POSITIVA' && 'sentiment_satisfied'}
      {type === 'NEUTRAL' && 'sentiment_neutral'}
      {type === 'NEGATIVA' && 'sentiment_dissatisfied'}
      {type === 'MUY_NEGATIVA' && 'sentiment_very_dissatisfied'}
    </i>
  </div>
)


const ModalEvaluacionActitudAlumno = ({ open, evaluaciones, alumno='', onSubmit, onClose }) => {

  const [valoracion, setValoracion] = useState('')

  const evaluacionActitudExistente = evaluaciones && evaluaciones.find(e=> e.tipo === 'actitud')

  useEffect(()=> {
    if (evaluacionActitudExistente) setValoracion(evaluacionActitudExistente.datos.actitud)
  }, [evaluacionActitudExistente])

  useEffect(()=> {
    if (open) setValoracion('')
  }, [open])

  const handleEvaluacionChange = (type)=> {
    if (evaluacionActitudExistente) return
    setValoracion(type)
  }

  const handleSubmit = ()=> {
    if (evaluacionActitudExistente) onClose()
    else onSubmit({ valoracion })
  }

  const isSubmitDisabled = isBlank(valoracion)

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Evaluación de {alumno}</DialogTitle>
      <DialogContent className={css.main}>
        {evaluacionActitudExistente && (
          <Alert className={css.alert} severity="warning">
            Ya has valorado a este alumno. Solo se puede valorar al alumno 1 vez al día.
          </Alert>
        )}
        <DialogContentText className={css.intro}>
          Indica el nivel de atención que el alumno está poniendo en esta clase.
        </DialogContentText>
        <div className={css.faces}>
          {ACTITUDES.map((type)=> (
            <Face
              key={type}
              type={type}
              isSelected={type === valoracion}
              onClick={()=> handleEvaluacionChange(type)}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          disabled={isSubmitDisabled}
          color='primary'
          onClick={handleSubmit}
        >
          {evaluacionActitudExistente ? 'Aceptar' : 'Enviar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalEvaluacionActitudAlumno