import { Link, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Card, CardMedia, Chip, Typography } from '@mui/material'
import AlumnoHeader from '../../../components/AlumnoHeader/AlumnoHeader'
import PageLoading from '../../../components/PageLoading/PageLoading'
import { getAlumno, getFotoAlumno } from '../../../utils/api/alumnos'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { dateFromUnixTimestamp, dateToStringLong } from '../../../utils/date'
import { getPerfilAcademico } from '../../../utils/model/alumnos'
import useAlumno from '../../../hooks/useAlumno'
import { getBienvenidaAlumno, getCondicionesContratacionURL, getDocumentoRGPD } from '../../../utils/documents'
import { getAuth } from '../../../utils/auth'
import DatosPersonales from './components/DatosPersonales/DatosPersonales'
import DatosTutores from './components/DatosTutores/DatosTutores'
import DatosConnect from './components/DatosConnect/DatosConnect'
import user from './profile.jpg'
import css from './Ficha.module.css'
import DatosFacturacion from './components/DatosFacturacion/DatosFacturacion'
import DatosClassfy from './components/DatosClassfy/DatosClassfy'

const Ficha = ()=> {

  const userData = getAuth()
  const notification = useNotification()
  const navigate = useNavigate()
  const { id, nombre } = useAlumno()

  const { isLoading, data: fichaAlumno={} } = useQuery({
    queryKey: ['alumnos', id], 
    queryFn: () => getAlumno(id)
      .then(datos=> ({
        ...datos,
        fechaNacimiento: dateFromUnixTimestamp(datos.fechaNacimiento),
        fechaFinMatricula: dateFromUnixTimestamp(datos.fechaFinMatricula),
        perfilAcademico: getPerfilAcademico({
          curso: datos.cursoEstudios, 
          nivel: datos.nivelEstudios, 
          centroEstudios: datos.centroEstudios
        })
      }))
      .catch(err=> {
        notification.error({ title: 'Error obteniendo datos de alumno', content: err })
        navigate('/alumnos')
        return {}
      })
  })

  const { data: fotoAlumno = {} } = useQuery({
    queryKey: ['foto-alumno', id], 
    queryFn: () => getFotoAlumno({ alumno: id })
    .then(datos=> datos.foto)
    .catch(() => null)
  })


  const condicionesURL = getCondicionesContratacionURL({ alumno: nombre, academia: fichaAlumno.centro })
  const rgpdURL = getDocumentoRGPD({ alumno: fichaAlumno.nombre, academia: fichaAlumno.centro })
  const bienvenidaURL = getBienvenidaAlumno({ alumno: fichaAlumno.nombre, academia: fichaAlumno.centro })

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <AlumnoHeader label='Ficha' />
        <div className={css.alumno}>
          <Card variant='outlined' className={css.summary}>
            <CardMedia
              className={css.image}
              component='img'
              image={fotoAlumno || user}
              alt={nombre}
            />
            <div className={css.content}>
              <Typography variant='h6' component='h2'>
                {nombre}
              </Typography>
              <Typography variant='body2' component='p'>
                {fichaAlumno.perfilAcademico}
              </Typography>
              <section className={css.section} data-section="matricula">
                <Typography variant='body2' component='p'>
                  Caducidad de la matrícula: <strong>{dateToStringLong(fichaAlumno.fechaFinMatricula)}</strong>
                </Typography>
              </section>
              <section className={css.section} data-section="lead">
                {fichaAlumno.leadId ? (
                  <Chip 
                    className={css.chip}
                    icon={<i className='material-icons'>face</i>} 
                    component={Link}
                    to={`/leads/${fichaAlumno.leadId}/ficha`}
                    label="Ver ficha lead" 
                    variant="outlined" 
                  />
                ) : (
                  <Typography variant='body2' component='p'>
                    No hay lead asociado a este alumno
                  </Typography>
                )}
              </section>
              <section className={css.section} data-section="documentacion">
                <Chip 
                  className={css.chip}
                  icon={<i className='material-icons'>summarize</i>} 
                  component="a"
                  href={condicionesURL}
                  target="_blank"
                  label="Condiciones contratacion" 
                  variant="outlined" 
                />
                <Chip 
                  className={css.chip}
                  icon={<i className='material-icons'>summarize</i>} 
                  component="a"
                  href={rgpdURL}
                  target="_blank"
                  label="Aceptación RGPD" 
                  variant="outlined" 
                />
                <Chip 
                  className={css.chip}
                  icon={<i className='material-icons'>summarize</i>} 
                  component="a"
                  href={bienvenidaURL}
                  target="_blank"
                  label="Bienvenida alumno" 
                  variant="outlined" 
                />
              </section>
              {userData.centro !== fichaAlumno.centro && (
                <section className={css.section} data-section="academia">
                  <Typography className={css.academy} variant='body2' component='p'>
                    <i className='material-icons'>school</i>
                    Alumno de <strong>{fichaAlumno.centro}</strong>
                  </Typography>
                </section>
              )}
            </div>
          </Card>
          <div className={css.ficha}>
            <div className={css.accordion}>
              <DatosPersonales alumno={fichaAlumno} />
            </div>
            <div className={css.accordion}>
              <DatosTutores tutor1={fichaAlumno.tutor1} tutor2={fichaAlumno.tutor2} />
            </div>
            <div className={css.accordion}>
              <DatosFacturacion facturacion={fichaAlumno.facturacion} />
            </div>
            <div className={css.accordion}>
              <DatosClassfy alumno={fichaAlumno} />
            </div>
            <div className={css.accordion}>
              <DatosConnect alumno={fichaAlumno} />
            </div>
          </div>
        </div>
      </div>
    </PageLoading>
  )
}

export default Ficha
