import { dateToTimeString } from "../date"

export const mergeClases = (clases)=> {
  // Saca las fechas de las clases (quitamos duplicados)
  const fechas = clases
    .map(clase=> clase.fecha)
    .filter((fecha, i, arr)=> arr.indexOf(fecha) === i)
    .sort()
  // Agrupa las clases por fecha
  const clasesFecha = fechas.map(fecha=> ({
    fecha,
    clases: clases
      .filter(clase=> clase.fecha === fecha)
      .sort((a, b)=> {
        if (a.centro !== b.centro) return a.centro.localeCompare(b.centro)
        return a.hora.localeCompare(b.hora)
      })
  }))
  // Fusionar las clases si tienen el mismo centro
  const clasesAgrupadas = clasesFecha
    .map(clasesDia=> ({
      ...clasesDia,
      clases: mergeClasesSameDay(clasesDia.clases)
    }))
  return clasesAgrupadas
}

export const mergeClasesSameDay = (clases)=> {
  return clases
    .reduce((clasesAgrupadas, clase)=> {
      if (clasesAgrupadas.length === 0) return [clase]
      const ultimaClase = clasesAgrupadas[clasesAgrupadas.length-1]
      // Combinamos si coinciden en hora y centro
      const horasConsecutivas = ultimaClase.hora.substr(-5) === clase.hora.substring(0, 5)
      const mismoCentro = ultimaClase.centro === clase.centro
      if (horasConsecutivas && mismoCentro) {
        clasesAgrupadas[clasesAgrupadas.length-1] = {
          ...ultimaClase,
          hora: `${ultimaClase.hora.substr(0, 5)}-${clase.hora.substr(-5)}`
        }
        return clasesAgrupadas
      }
      // Else get them both together
      return [...clasesAgrupadas, clase]
    }, [])
    .sort((a, b)=> a.hora.localeCompare(b.hora))
}

/*
 * Get the most relevant hour from a list of hours
 * hours will be an array of "HH:mm-HH:mm" strings
 */
export const getMostRelevantHour = (hours)=> {
  if (!hours || hours.length === 0) return null
  const now = dateToTimeString(new Date())
  let hora_activa = hours[0]
  hours.forEach(hour=> {
    if (hour.split('-')[0] < now) {
      hora_activa = hour
    }
  })
  return hora_activa
}