import { Badge, Collapse, IconButton, Link, TableCell, TableRow, TextField } from "@mui/material"
import EstadoAsistencia from "../../../../components/EstadoAsistencia/EstadoAsistencia"
import { isBlank } from "../../../../utils/string"
import { isClassfy } from "../../../../utils/academia"
import useResponsive from "../../../../hooks/useResponsive"
import css from './ClaseRow.module.css'

const ClaseRow = ({ asistencia, centro, isOpen, onOpen, onChange, onClick })=> {

  const { size, isScreenBiggerThan } = useResponsive()

  const getColspan = ()=> {
    if (size === 'xl' || size === 'lg') return 6
    if (size === 'md') return 5
    return 4
  }
  
  return (
    <>
      <TableRow 
        className={css.row} 
        data-clase-fuera={asistencia.centro !== centro}
        data-alumno-fuera={asistencia.centroAlumno !== centro}
      >
        <TableCell className={css.mainRowCell}>
          <div className={css.alumno}>
            {isClassfy(asistencia.centro) && (
              <i className='material-icons'>laptop</i>
            )}
            <Link
              className={css.alumnoLink}
              href={`/alumnos/${asistencia.alumnoId}/ficha`}
            >
              {asistencia.alumno}
            </Link>
          </div>
        </TableCell>
        <TableCell 
          className={css.mainRowCell}
          data-visibility={isScreenBiggerThan('sm') ? 'visible' : 'invisible'} 
        >
          {asistencia.asignatura}
        </TableCell>
        <TableCell 
          className={css.mainRowCell}
          data-visibility={isScreenBiggerThan('md') ? 'visible' : 'invisible'} 
        >
          {asistencia.clase.replaceAll("_", " ")}
        </TableCell>
        <TableCell className={css.mainRowCell} align="center">
          <EstadoAsistencia
            asistencia={asistencia}
            centro={centro}
            onClick={(asistenciaAlumno)=> onChange(asistenciaAlumno, asistencia.id)}
          />
        </TableCell>
        <TableCell className={css.mainRowCell} align="center">
          <IconButton
            color='secondary'
            size="small"
            onClick={()=> onClick(asistencia, 'nota')}
          >
            <i className='material-icons'>edit_note</i>
          </IconButton>
          <IconButton
            color='primary'
            size="small"
            onClick={()=> onClick(asistencia, 'evaluacion')}
          >
            <i className='material-icons'>assistant</i>
          </IconButton>
        </TableCell>
        <TableCell className={css.mainRowCell}>
          <IconButton
            size="small"
            onClick={onOpen}
          >
            <Badge color="primary" variant="dot" invisible={isBlank(asistencia.observaciones)}>
              <i className='material-icons'>
                {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            </Badge>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow 
        className={css.row} 
        data-clase-fuera={asistencia.centro !== centro}
        data-alumno-fuera={asistencia.centroAlumno !== centro}
      >
        <TableCell className={css.additionalRow} colSpan={getColspan()}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            {asistencia.centro !== centro && (
              <p className={css.extraInfo}>
                <i className='material-icons'>warning</i>
                <span>
                  Está dando clase en <strong>{asistencia.centro}</strong>
                </span>
              </p> 
            )}
            {asistencia.centroAlumno !== centro && (
              <p className={css.extraInfo}>
                <i className='material-icons'>info</i>
                <span>
                  Alumno con ficha en <strong>{asistencia.centro}</strong>
                </span>
              </p> 
            )}
            <div className={css.observaciones}>
              <TextField
                name='observaciones'
                label='Observaciones'
                variant='outlined'
                margin='dense'
                value={asistencia.observaciones || 'Sin observaciones'}
                maxRows={8}
                multiline
                fullWidth
              />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ClaseRow