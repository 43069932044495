import { GET, POST, DELETE } from '../request'

export const getAlumno = id => GET('/api/profes/alumnos/alumno', { id })
export const getAlumnoName = id => GET('/api/profes/alumnos/alumno/name', { id })

export const getAlumnoList = () => GET('/api/profes/alumnos/alumno/list')
export const getAlumnoListYear = periodo => GET(`/api/profes/alumnos/alumno/list/year`, { periodo })
export const getAlumnoListSearch = filtro => GET('/api/profes/alumnos/alumno/list/search', { filtro })

export const getFotoAlumno = ({ alumno }) => GET('/api/profes/alumnos/foto-alumno', { alumno })

export const getNotasAlumnoList = alumno => GET('/api/profes/alumnos/notas-alumno/list', { alumno })

export const getNotasProfesorList = alumno => GET('/api/profes/alumnos/notas-profesor/list', { alumno })
export const createNotaProfesor = ({ alumno, nota }) => POST('/api/profes/alumnos/notas-profesor', { alumno, nota })
export const deleteNotaProfesor = id => DELETE('/api/profes/alumnos/notas-profesor', { id })
