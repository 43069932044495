import { Card, CardContent, Link, Typography } from "@mui/material"
import LeadHeader from "../../../components/LeadHeader/LeadHeader"
import PageLoading from "../../../components/PageLoading/PageLoading"
import useLead from "../../../hooks/useLead"
import { capitalize } from "../../../utils/string"
import css from './Contactos.module.css'

const getContactIcon = (formaContacto) => {
  switch (formaContacto) {
    case 'llamada':
    case 'telefono': return 'call'
    case 'email':
    case 'e-mail': return 'forward_to_inbox'
    case 'persona':
    case 'tienda': return 'partner_exchange'
    case 'web': return 'screen_share'
    case 'whatsapp': return 'forum'
    default: return 'sync_alt'
  }
}

const getPruebaIcon = (asistencia)=> {
  if (asistencia === 'VIENE') return 'check_circle'
  else if (asistencia === 'FALTA_JUSTIFICADA') return 'highlight_off'
  else if (asistencia === 'FALTA') return 'close'
  else return 'help_outline'
}

const showProximosPasos = (estadoFeedback)=> {
  [ 'PRUEBA', 'CLIENTE_CAPTADO', 'CLIENTE_PERDIDO', 'LEAD_NOVALIDO' ].includes(estadoFeedback)
}

const LeadCard = ({ contacto, index }) => (
  <Card className={css.card}>
    <CardContent>
      <div className={css.header}>
        <Typography variant='h6'>
          {contacto.estadoFeedback.replace(/_/g, ' ')}
        </Typography>
        <div className={css.index}>{index + 1}</div>
      </div>
      <Typography className={css.detailGroup}>
        Detalles contacto
      </Typography>
      <Typography className={css.detail} variant='body2' component='p'>
        <i className="material-icons">event</i> {contacto.fechaContacto}
      </Typography>
      <Typography className={css.detail} variant='body2' component='p'>
        <i className="material-icons">face</i> {contacto.usuarioContacto}
      </Typography>
      {contacto.centroContacto && (
        <Typography className={css.detail} variant='body2' component='p'>
          <i className="material-icons">school</i> {contacto.centroContacto}
        </Typography>
      )}
      {contacto.formaContacto && (
        <Typography className={css.detail} variant='body2' component='p'>
          <i className="material-icons">{getContactIcon(contacto.formaContacto)}</i>{' '}
          {capitalize(contacto.formaContacto)} 
        </Typography>
      )}
      {contacto.estadoFeedback === 'PRUEBA' && (
        <>
          <Typography className={css.detailGroup}>
            Detalles prueba
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">event</i> {contacto.proximoContacto} → {contacto.hora}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">school</i> {contacto.centroPrueba}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">emoji_people</i> {contacto.profesor || 'Sin profesor'}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">lightbulb_circle</i> {contacto.asignatura}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'  data-confirmada={contacto.confirmada ? 'yes' : 'no'}>
            <i className="material-icons">{contacto.confirmada ? 'check_circle' : 'psychology_alt'}</i> 
            {contacto.confirmada ? 'Prueba confirmada' : 'Pendiente de confirmar'}
          </Typography>
          <Typography className={css.detail} variant='body2' component='p' data-asistencia={contacto.asistencia}>
            <i className="material-icons">{getPruebaIcon(contacto.asistencia)}</i>
            {contacto.asistencia.replace(/_/g, ' ')}
          </Typography>
        </> 
      )}
      {contacto.valoracion && (
        <>
          <Typography className={css.detailGroup}>
            Valoracion prueba
          </Typography>
          <blockquote className={css.blockInfo}>
            {contacto.valoracion}
          </blockquote>
        </>
      )}
      {contacto.presupuesto && (
        <>
          <Typography className={css.detailGroup}>
            Presupuesto ofrecido
          </Typography>
          <blockquote className={css.blockInfo}>
            {contacto.presupuesto}
          </blockquote>
        </>
      )}
      {contacto.alumnoId && (
        <>
          <Typography className={css.detailGroup}>
            Ficha de alumno
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">person_outline</i>
            <Link className={css.link} href={`/alumnos/${contacto.alumnoId}/ficha`}>
              {contacto.alumno}
            </Link>
          </Typography> 
        </>
      )}
      {contacto.causa && (
        <>
          <Typography className={css.detailGroup}>
            Motivo de cierre
          </Typography>
          <Typography className={css.detail} variant='body2' component='p' data-confirmada='no'>
            <i className="material-icons">close</i>
            {contacto.causa}
          </Typography>
        </>
      )}
      {contacto.observaciones && (
        <>
          <Typography className={css.detailGroup}>
            Observaciones
          </Typography>
          <blockquote className={css.blockInfo}>
            {contacto.observaciones}
          </blockquote>
        </>
      )}
      {showProximosPasos(contacto.estadoFeedback) && (
        <>
          <Typography className={css.detailGroup}>
            Próximos pasos
          </Typography>
          <Typography className={css.detail} variant='body2' component='p'>
            <i className="material-icons">event</i> {contacto.proximoContacto}
          </Typography>
          {contacto.fechaIncorporacion && (
            <Typography className={css.detail} variant='body2' component='p'>
              <i className="material-icons">event</i> {contacto.fechaIncorporacion} (Fecha estimada incorporación)
            </Typography>
          )}
        </>
      )}
    </CardContent>
  </Card>
)

const Contactos = () => {

  const { isLoading, lead } = useLead()
  const { contactos=[] } = lead

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.main}>
        <LeadHeader label='Contactos' />
        <div className={css.content}>
          {contactos.map((contacto, index) => (
            <LeadCard key={index} contacto={contacto} index={index} />
          ))}
        </div>
      </div>
    </PageLoading>
  )
}

export default Contactos