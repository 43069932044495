import { GET, PUT } from '../request'

export const getAsistenciaTotals = ({ fecha }) => GET('/api/profes/planificacion/asistencia/list/totals', { fecha })

export const getAsistenciasAlumno = ({ alumno, fecha, fechaFin }) => GET('/api/profes/planificacion/asistencia/list/alumno', { alumno, fecha, fecha_fin: fechaFin })

export const getAsistenciaList = ({ fecha, hora }) => GET('/api/profes/planificacion/asistencia/list', { fecha, hora })
export const getAsistenciaLeadList = ({ fecha, hora }) => GET('/api/profes/planificacion/asistencia/feedback/list', { fecha, hora })

export const updateAsistencia = ({ id, asistencia, observaciones, multiple=false }) => PUT('/api/profes/planificacion/asistencia', { id, asistencia, observaciones, multiple })
export const updateAsistenciaLead = ({ id, fecha, hora, asistencia, observaciones }) =>  PUT('/api/profes/planificacion/asistencia/feedback', { id, fecha, hora, asistencia, observaciones })
