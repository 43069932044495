import { useState } from 'react'
import { getLeadListSearch } from '../../utils/api/leads'
import { InputAdornment, Link, TextField, Typography } from '@mui/material'
import InformationTable from '../../components/Table/InformationTable'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import PageLoading from '../../components/PageLoading/PageLoading'
import useDebounce from '../../hooks/useDebounce'
import { dateToString } from '../../utils/date'
import { dateSort } from '../../utils/table'
import css from './Leads.module.css'

const Leads = ()=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')

  const debouncedSearch = useDebounce(search, 500)

  const { isLoading, data: leadList=[] } = useQuery({
    queryKey: ['marketing', 'feedback', 'list', 'search', debouncedSearch], 
    enabled: debouncedSearch.length > 2,
    queryFn: ()=> getLeadListSearch(debouncedSearch)
      .then(datos=> datos.map(lead=> ({
        ...lead,
        ultimoContacto: dateToString(lead.ultimoContacto),
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los alumnos', content: err })
        return []
      })
  })

  const isValidSearch = debouncedSearch.length > 2
  const hasResults = leadList.length > 0

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.header}>
        <Typography variant='h5' >
          Buscar leads
        </Typography>
        <TextField
          value={search}
          onChange={e=> setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <i className='material-icons'>search</i>
              </InputAdornment>
            ),
          }}
          variant='standard'
        />
      </div>
      {!isValidSearch && (
        <Typography className={css.placeholder} variant='body1'>
          Introduce al menos 3 caracteres para empezar a buscar
        </Typography>
      )}
      {isValidSearch && !hasResults && (
        <Typography className={css.placeholder} variant='body1'>
          No se han encontrado resultados
        </Typography> 
      )}
      {isValidSearch && hasResults && (
        <InformationTable
          details={[
            { title: 'Nombre', key: 'nombre' },
            { title: 'Centro', key: 'centro' },
            { title: 'Centro estudios', key: 'centroEstudios' },
            { title: 'Último contacto', key: 'ultimoContacto', sortFunction: dateSort },
          ]}
          data={leadList.map((lead) => ({
            nombre: (
              <Link
                className={css.link}
                href={`/leads/${lead.id}/ficha`}
              >
                {lead.alumno || lead.contacto}
              </Link>
            ),
            centro: lead.centro || '--',
            centroEstudios: lead.centroEstudios || '--',
            ultimoContacto: lead.ultimoContacto || '--',
          }))}
          isFetching={isLoading}
        />
      )}
    </PageLoading>
  )
}

export default Leads
