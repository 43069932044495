import { useState } from 'react'
import { getAlumnoListSearch } from '../../utils/api/alumnos'
import { InputAdornment, Link, TextField, Typography } from '@mui/material'
import InformationTable from '../../components/Table/InformationTable'
import { useQuery } from '@tanstack/react-query'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import PageLoading from '../../components/PageLoading/PageLoading'
import useDebounce from '../../hooks/useDebounce'
import { dateSort } from '../../utils/table'
import { dateToString, getDateFromObjectId } from '../../utils/date'
import css from './Alumnos.module.css'

const Alumnos = ()=> {

  const notification = useNotification()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const { isLoading, data: alumnoList=[] } = useQuery({
    queryKey: ['alumno', 'list', 'search', debouncedSearch], 
    enabled: debouncedSearch.length > 2,
    queryFn: ()=> getAlumnoListSearch(debouncedSearch)
      .then(datos=> datos.map(alumno=> ({
        ...alumno,
        fechaRegistro: dateToString(getDateFromObjectId(alumno.id)),
      })))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar los alumnos', content: err })
        return []
      })
  })

  const isValidSearch = debouncedSearch.length > 2
  const hasResults = alumnoList.length > 0

  return (
    <PageLoading isLoading={isLoading}>
      <div className={css.header}>
        <Typography variant='h5' >
          Buscar alumnos
        </Typography>
        <TextField
          value={search}
          onChange={e=> setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <i className='material-icons'>search</i>
              </InputAdornment>
            ),
          }}
          variant='standard'
        />
      </div>
      {!isValidSearch && (
        <Typography className={css.placeholder} variant='body1'>
          Introduce al menos 3 caracteres para empezar a buscar
        </Typography>
      )}
      {isValidSearch && !hasResults && (
        <Typography className={css.placeholder} variant='body1'>
          No se han encontrado resultados
        </Typography> 
      )}
      {isValidSearch && hasResults && (
        <InformationTable
          details={[
            { title: 'Nombre', key: 'nombre' },
            { title: 'Fecha registro', key: 'fechaRegistro', sortFunction: dateSort },
            { title: 'Centro', key: 'centro' },
          ]}
          data={alumnoList.map((alumno) => ({
            nombre: (
              <Link
                className={css.link}
                href={`/alumnos/${alumno.id}/ficha`}
              >
                {alumno.nombre}
              </Link>
            ),
            fechaRegistro: alumno.fechaRegistro,
            centro: alumno.centro || '--',
          }))}
          isFetching={isLoading}
        />
      )}
    </PageLoading>
  )
}

export default Alumnos
