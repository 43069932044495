const getCurso = (curso)=> {
  if (curso) return `${curso}º`
  if (curso === 0) return ''
  return '[Curso desconocido]'
}

export const getPerfilAcademico = ({ curso, nivel, centroEstudios }) => {
  const infoCurso = getCurso(curso)
  const infoNivel = nivel ? `${nivel} ` : '[Nivel desconocido] '
  const infoCentro = centroEstudios ? `${centroEstudios}` : '[Centro de estudios desconocido]'
  return `${infoCurso} ${infoNivel} - ${infoCentro}`
}