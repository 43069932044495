import { useState } from 'react'
import { Route, Routes } from 'react-router'
import Header from '../Header/Header'
import SideMenu from '../SideMenu/SideMenu'
import Content from '../Content/Content'
import Help from '../Help/Help'

import Helpdesk from '../../views/Helpdesk/Helpdesk'
import Dashboard from '../../views/Dashboard/Dashboard'
import Planificacion from '../../views/Planificacion/Planificacion'
import Perfil from '../../views/Perfil/Perfil'

import AlumnoAsistencias from '../../views/Alumno/Asistencias/Asistencias'
import AlumnoFicha from '../../views/Alumno/Ficha/Ficha'
import AlumnoNotas from '../../views/Alumno/Notas/Notas'
import Alumnos from '../../views/Alumnos/Alumnos'

import LeadFicha from '../../views/Lead/Ficha/Ficha'
import LeadContactos from '../../views/Lead/Contactos/Contactos'
import Leads from '../../views/Leads/Leads'

import Horario from '../../views/Horario/Horario'

const Layout = () => {
  const [help, setHelp] = useState(false)

  return (
    <>
      <Header onHelpClick={() => setHelp(true)} />
      <SideMenu />
      <Content>
        <Routes>
          <Route path='/' element={<Dashboard />} />

          <Route path='/perfil' element={<Perfil />} />

          <Route path='/planificacion' element={<Planificacion />} />

          <Route path='/horario' element={<Horario />} />

          <Route path='/alumnos' element={<Alumnos />} />
          <Route path='/leads' element={<Leads />} />

          <Route path='/alumnos/:id/ficha' element={<AlumnoFicha />} />
          <Route path='/alumnos/:id/asistencias' element={<AlumnoAsistencias />} />
          <Route path='/alumnos/:id/notas' element={<AlumnoNotas />} />

          <Route path='/leads/:id/ficha' element={<LeadFicha />} />
          <Route path='/leads/:id/contactos' element={<LeadContactos />} />

          <Route path='/helpdesk' element={<Helpdesk />} />

        </Routes>
      </Content>

      <Help open={help} onClose={() => setHelp(false)} />
    </>
  )
}

export default Layout
