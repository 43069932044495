import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import TextLabel from '../../../../../components/form/TextLabel/TextLabel'
import css from './DatosContacto.module.css'

const DatosContacto = ({ lead })=> {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  const isPropioAlumno = lead.contacto === 'Propio Alumno'

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Datos contacto</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={css.details}>
        <div className={css.column}>
          <TextLabel
            className={css.field}
            name='relacion'
            label='Persona de contacto'
            value={lead.contacto}
          />
          <TextLabel
            className={css.field}
            name='nombre'
            label='Nombre contacto'
            value={lead.nombreContacto}
          />
          <TextLabel
            className={css.field}
            name='email'
            label='E-mail contacto'
            value={lead.emailContacto}
          />
          <TextLabel
            className={css.field}
            name='telefono'
            label='Teléfono contacto'
            value={lead.telefonoContacto}
          />
        </div>
        <div className={css.column}>
          <TextLabel
            className={css.field}
            name='relacion'
            label='Persona de interés'
            value='Alumno'
            disabled
          />
          <TextLabel
            className={css.field}
            name='nombre'
            label='Nombre del alumno'
            value={lead.nombreAlumno}
            disabled={isPropioAlumno}
          />
          <TextLabel
            className={css.field}
            name='email'
            label='E-mail del alumno'
            value={lead.emailAlumno}
            disabled={isPropioAlumno}
          />
          <TextLabel
            className={css.field}
            name='telefono'
            label='Teléfono del alumno'
            value={lead.telefonoAlumno}
            disabled={isPropioAlumno}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosContacto