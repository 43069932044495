import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Chip } from '@mui/material'
import TextLabel from '../../../../../components/form/TextLabel/TextLabel'
import css from './DatosEstudios.module.css'

const DatosEstudios = ({ lead })=> {

  const [isExpanded, setExpanded] = useState(true)

  const handleChange = (_event, isExpanded) => {
    setExpanded(isExpanded)
  }

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<i className='material-icons'>expand_more</i>}
      >
        <div className={css.label}>
          <Typography className={css.title} variant="h2">Datos de estudios</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='centro'
            label='Centro de estudios'
            value={lead.centroEstudios}
          />
        </div>
        <div className={css.row}>
          <TextLabel
            className={css.field}
            name='curso'
            label='Curso'
            value={lead.curso === 0 ? 'No aplicable' : lead.curso}
          />
          <TextLabel
            className={css.field}
            name='nivel'
            label='Nivel'
            value={lead.tipologiaAcademica}
          />
        </div>
        <div className={css.row}>
          <div className={css.field}>
            <Typography className={css.fieldTitle} variant='body2'>Asignaturas de interés</Typography>
            {lead.asignaturas.length === 0 && (
              <Typography variant='body2'>No hay información sobre sus asignaturas de interés</Typography> 
            )}
            {lead.asignaturas.map(asignatura=> (
              <Chip
                key={asignatura.id}
                className={css.chip}
                label={`${asignatura.nombre} (${asignatura.nivel})`}

              />
            ))}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DatosEstudios