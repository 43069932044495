import { IconButton } from '@mui/material'
import Calendar from '../../../components/Calendar/Calendar'
import { getAuth } from '../../../utils/auth'
import { dateFromString, dateToMonth, dateToString, forwardDays, forwardMonths, getStartOfMonth, isSameMonth, monthToString } from '../../../utils/date'
import { useNotification } from '../../../context/NotificationManager/NotificationManager'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getAsistenciasAlumno } from '../../../utils/api/asistencias'
import useAlumno from '../../../hooks/useAlumno'
import css from './Asistencias.module.css'
import AlumnoHeader from '../../../components/AlumnoHeader/AlumnoHeader'

const AsistenciaCalendario = ({ asignatura, asistencia, clase, nivel, hora, centro, dimmed })=> {
  const userData = getAuth()
  return (
    <div className={css.calendarEvent} data-clase={clase} data-status={dimmed ? 'dimmed' : 'normal'}>
      <p className={css.calendarEventRow}>
        <span className={css.calendarEventAsistencia} data-asistencia={asistencia} />
        <p className={css.calendarEventAsignatura}>
          {asignatura} <span className={css.calendarEventDetail}>({nivel.substring(0, 4)})</span>
        </p>
      </p>
      <p className={css.calendarEventRow}>
        {hora} {userData.centro === centro ? '' : <span className={css.calendarEventDetail}>({centro})</span>}
      </p>
    </div>
  )
}

const Asistencias = () => {

  const { id } = useAlumno()
  const notification = useNotification()

  const [mes, setMes] = useState(getStartOfMonth())

  const handleMonthChange = (offset) => {
    const newMes = new Date(mes)
    newMes.setMonth(newMes.getMonth() + offset)
    setMes(newMes)
  }

  const { data: asistenciaList=[] } = useQuery({
    queryKey: ['asistencias', 'list', 'alumno', id, mes], 
    queryFn: ()=> getAsistenciasAlumno({
      alumno: id,
      fecha: dateToString(forwardDays(mes, -6)),
      fechaFin: dateToString(forwardDays(forwardMonths(mes, 1), 5))
    })
      .then(datos=> datos
        .sort((a, b)=> {
          if (a.fecha < b.fecha) return -1
          if (a.fecha > b.fecha) return 1
          if (a.hora < b.hora) return -1
          if (a.hora > b.hora) return 1
          return 0
        })
        .map(asistencia=> ({
        ...asistencia,
        fecha: dateToString(asistencia.fecha)
        })
      ))
      .catch(err=> {
        notification.error({ title: 'Error al recuperar las asistencias', content: err })
        return []
      })
  })


  const asistenciasCalendario = asistenciaList
    .map(asistencia=> ({
      fecha: asistencia.fecha,
      component: (
        <AsistenciaCalendario
          key={asistencia.id}
          clase={asistencia.clase.toLowerCase()}
          asistencia={asistencia.asistencia.toLowerCase()}
          asignatura={asistencia.asignatura}
          nivel={asistencia.nivel}
          hora={asistencia.hora}
          centro={asistencia.centro}
          dimmed={!isSameMonth(dateFromString(asistencia.fecha), mes)}
        />
      )
    }))
    .reduce((acc, asistencia)=> {
      return {
        ...acc,
        [asistencia.fecha]: [
          ...(acc[asistencia.fecha] || []),
          asistencia.component
        ]
      }
    }, {})

  return (
    <div className={css.main}>
      <AlumnoHeader label='Asistencias' />
      <div className={css.calendar}>
        <div className={css.monthSelection}>
          <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(-1)}>
            <i className='material-icons'>arrow_back</i>
          </IconButton>
          <p className={css.calendarMonth}>
            {dateToMonth(mes)}
          </p>
          <IconButton className={css.monthIcon} size='small' onClick={()=> handleMonthChange(+1)}>
            <i className='material-icons'>arrow_forward</i>
          </IconButton>
        </div>
        <Calendar
          month={monthToString(mes)}
          events={asistenciasCalendario}
        />
      </div>
    </div>
  )
}

export default Asistencias
