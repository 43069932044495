import { IconButton } from "@mui/material"
import css from './EstadoAsistencia.module.css'

const EstadoAsistencia = ({ asistencia, centro, onClick })=> {
  // Las asistencias de otro centro no son interactivas
  if (asistencia.centro !== centro) {
    if (asistencia.asistencia === 'POR_DEFINIR') {
      return (
        <i className='material-icons' data-asistencia='POR_DEFINIR'>help_outline</i>
      )
    }
    else if (asistencia.asistencia === 'VIENE') {
      return (
        <i className='material-icons' data-asistencia='VIENE'>check</i>
      )
    }
    else if (asistencia.asistencia === 'FALTA_JUSTIFICADA') {
      return (
        <i className='material-icons' data-asistencia='FALTA_JUSTIFICADA'>highlight_off</i>
      )
    }
    else if (asistencia.asistencia === 'FALTA') {
      return (
        <i className='material-icons' data-asistencia='FALTA'>close</i>
      )
    }
  }
  // Mostramos las asistencias de este centro
  if (asistencia.asistencia === 'POR_DEFINIR') {
    return (
      <>
        <IconButton className={css.button} data-asistencia='VIENE' onClick={()=> onClick('VIENE')}>
          <i className='material-icons'>check</i>
        </IconButton>
        <IconButton className={css.button} data-asistencia='FALTA_JUSTIFICADA' onClick={()=> onClick('FALTA_JUSTIFICADA')}>
          <i className='material-icons'>highlight_off</i>
        </IconButton>
        <IconButton className={css.button} data-asistencia='FALTA' onClick={()=> onClick('FALTA')}>
          <i className='material-icons'>close</i>
        </IconButton>
      </>
    )
  }
  else if (asistencia.asistencia === 'VIENE') {
    return (
      <IconButton data-asistencia='VIENE' onClick={()=> onClick('POR_DEFINIR')}>
        <i className='material-icons'>check</i>
      </IconButton>
    )
  }
  else if (asistencia.asistencia === 'FALTA_JUSTIFICADA') {
    return (
      <IconButton data-asistencia='FALTA_JUSTIFICADA' onClick={()=> onClick('POR_DEFINIR')}>
        <i className='material-icons'>highlight_off</i>
      </IconButton>
    )
  }
  else if (asistencia.asistencia === 'FALTA') {
    return (
      <IconButton data-asistencia='FALTA' onClick={()=> onClick('POR_DEFINIR')}>
        <i className='material-icons'>close</i>
      </IconButton>
    )
  }

  return null

}

export default EstadoAsistencia