import { DateTime } from "luxon"

export const MONTHS = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
export const WEEKDAYS = [ 'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado' ]

export const getToday = ()=> {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

const parseDate = value=> {
  if (!value) return null
  if (typeof value === 'number') return new Date(value)
  if (DateTime.isDateTime(value)) return value.toJSDate()
  return value
}

export const dateToDateTime = value=> {
  const fecha = parseDate(value)
  if (!fecha) return null
  return DateTime.fromJSDate(fecha)
}

export const dateToWeekday = value=> {
  const fecha = parseDate(value)
  if (!fecha) return ''
  const weekday = fecha.getDay()
  return WEEKDAYS[weekday]
}

export const dateToMonth = value=> {
  const fecha = parseDate(value)
  if (!fecha) return ''
  const month = fecha.getMonth()
  const year = fecha.getFullYear()
  return `${MONTHS[month]} ${year}`
}

export const monthToString = value=> {
  const fecha = parseDate(value)
  if (!fecha) return ''
  let month = (fecha.getMonth() + 1) + ''
  if (month.length === 1) month = '0' + month
  let year = fecha.getFullYear()
  return `${month}/${year}`
}

export const dateToString = value=> {
  const fecha = parseDate(value)
  if (!fecha) return ''
  let day = fecha.getDate() + ''
  if (day.length === 1) day = '0' + day
  let month = (fecha.getMonth() + 1) + ''
  if (month.length === 1) month = '0' + month
  let year = fecha.getFullYear()
  return `${day}/${month}/${year}`
}

export const dateToStringLong = value=> {
  const fecha = parseDate(value)
  if (!fecha) return ''
  let day = fecha.getDate() + ''
  if (day.length === 1) day = '0' + day
  let month = fecha.getMonth()
  let year = fecha.getFullYear()
  return `${day} de ${MONTHS[month]} de ${year}`
}

export const dateToTimeString = value=> {
  const fecha = parseDate(value)
  if (!fecha) return ''
  let hour = fecha.getHours() + ''
  if (hour.length === 1) hour = '0' + hour
  let minute = fecha.getMinutes() + ''
  if (minute.length === 1) minute = '0' + minute
  return `${hour}:${minute}`
}

export const dateFromString = (value)=> {
  if (!value) return null
  if (value.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/)) {
    const data = value.split('/')
    const year = +data[2]
    const month = +data[1] - 1
    const day = +data[0]
    return new Date(year, month, day, 0, 0, 0, 0)
  }
  else return null
}

export const dateFromUnixTimestamp = (value)=> {
  if (!value) return null
  return new Date(value)
}

export const dateFromMonthString = (value)=> {
  if (!value) return null
  if (value.match(/[0-9]{2}\/[0-9]{4}/)) {
    const data = value.split('/')
    const year = +data[1]
    const month = +data[0] - 1
    return new Date(year, month, 1, 0, 0, 0, 0)
  }
  else return null
}

export const dateFromMonthTextString = (value)=> {
  // El dato será tipo "NOVIEMBRE 2020"
  if (!value) return null
  const [month, year] = value.split(' ')
  const monthIndex = MONTHS.findIndex(mes=> mes.toLowerCase() === month.toLowerCase())
  return new Date(year, monthIndex, 1, 0, 0, 0, 0)
}

export const getDateWeekday = (value)=> {
  if (typeof value === 'number') value = dateToString(value)
  if (typeof value === 'string') value = dateFromString(value)
  const weekday = value.getDay()
  return WEEKDAYS[weekday]
}

export const dateFromTimeString = (value)=> {
  if (!value) return null
  if (value.match(/[0-9]{2}:[0-9]{2}/)) {
    const data = value.split(':')
    const hour = +data[0]
    const minute = +data[1]
    const date = new Date()
    return new Date(date.getYear(), date.getMonth(), date.getDate(), hour, minute, 0, 0)
  }
  else return null
}

export const isSameDay = (date_1, date_2)=> {
  if (!date_1 || !date_2) return false
  return (
    date_1.getDate() === date_2.getDate() && 
    date_1.getMonth() === date_2.getMonth() && 
    date_1.getFullYear() === date_2.getFullYear()
  )
}

export const isSameMonth = (date_1, date_2)=> {
  if (!date_1 || !date_2) return false
  return (
    date_1.getMonth() === date_2.getMonth() && 
    date_1.getFullYear() === date_2.getFullYear()
  )
}

export const dateFromTimestamp = date=> {
  return new Date(date.substring(0,4), date.substring(5,7), date.substring(8,10))
}

export const getStartOfMonth = ()=> {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

export const getRecentMonths = ({ date, forwardOptions, backwardOptions })=> {
  const months = []
  const start = new Date(date.getFullYear(), date.getMonth() - backwardOptions)
  const end = new Date(date.getFullYear(), date.getMonth() + forwardOptions)
  for (let i = start; i <= end; i.setMonth(i.getMonth() + 1)) {
    months.push({ value: monthToString(i), label: dateToMonth(i) })
  }
  return months
}

export const getMonthDaysInfo = (mes)=> {
  const weeks = []
  const active_month = mes.split('/')[0]-1
  const active_year = mes.split('/')[1]
  const first_of_month = new Date(active_year, active_month, 1)
  const last_of_month = new Date(active_year, active_month+1, 0)
  const day = first_of_month.getDay() || 7
  const first_day = new Date(active_year, active_month, 2-day)
  while (true) {
    const week = [0, 1, 2, 3, 4, 5, 6].map(day=> {
      return new Date(
        first_day.getFullYear(), 
        first_day.getMonth(), 
        first_day.getDate() + day + 7*weeks.length)
    })
    // Añadimos la semana
    weeks.push(week)
    // Comprobamos si nos hemos pasado
    if (weeks[weeks.length-1][6] >= last_of_month) break
  }
  return weeks
}

export const getMonthDays = (mes)=> {
  const active_month = mes.split('/')[0]-1
  const active_year = mes.split('/')[1]
  const first_of_month = new Date(active_year, active_month, 1)
  const days = new Array(35)
    .fill(1)
    .map((_, i)=> i+1)
    .map(i=> new Date(active_year, active_month, i))
    .filter(date=> date.getMonth() === first_of_month.getMonth())
  return days
}

export const getDateFromObjectId = (objectId)=> {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000)
}

export const getDateRelative = (value)=> {
  const fecha = parseDate(value)
  if (!fecha) return ''
  const today = new Date()
  const yesterday = new Date(today.getTime() - 86400 * 1000)
  const tomorrow = new Date(today.getTime() + 86400 * 1000)
  // Ajustamos el dia
  let fecha_value = `El ${dateToString(fecha)}`
  if (isSameDay(fecha, today)) fecha_value = 'Hoy'
  if (isSameDay(fecha, yesterday)) fecha_value = 'Ayer'
  if (isSameDay(fecha, tomorrow)) fecha_value = 'Mañana'
  // Ajustamos la hora
  const hour_value = dateToTimeString(fecha)
  if (hour_value !== '00:00') fecha_value = `${fecha_value} a las ${hour_value}`
  return fecha_value
}

export const forwardDays = (date, days)=> {
  const fecha = parseDate(date)
  if (!fecha) return null
  return new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate() + days)
}

export const forwardMonths = (date, months)=> {
  const fecha = parseDate(date)
  if (!fecha) return null
  return new Date(fecha.getFullYear(), fecha.getMonth() + months, fecha.getDate())
}