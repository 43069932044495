export const getTotales = ({ total })=> {
  if (!total) return { alumnos: 0, detalles: [] }
  const detalles = [ 'ALUMNOS', 'PRUEBAS' ].reduce((acc, curr)=> {
    if (!total[curr]) return acc
    return [
      ...acc, 
      { type: curr, value: total[curr] }
    ]
  }, [])
  return detalles
}